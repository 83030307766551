const LocationReviewsData = [
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Taylor Greene",
        "service_type": "Garage Door Insulation",
        "tech_name": "Julie H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Thomas George",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Brooks",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ryan C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "James Watson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Sherry French",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda J.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Jennifer Pena",
        "service_type": "Garage Door Off Track",
        "tech_name": "George L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Lisa West",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sarah J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Angel Summers",
        "service_type": "Garage Door Services",
        "tech_name": "Megan V.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Shawn Trevino",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Frederick P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "David Murray",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ashley H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Melinda Morgan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brett A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Mr. Steve Curry DDS",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Whitney J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "William Navarro",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Leslie W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Eric Lawson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bradley E.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Amy Cohen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kevin D.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Chelsea Tran",
        "service_type": "Garage Door Services",
        "tech_name": "Melissa W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Matthew Chung",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Dixon",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gary W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Amanda Joyce",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Johnny Lutz",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Mitchell C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Karen Stewart",
        "service_type": "Garage Door Repair",
        "tech_name": "Tyler Y.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Amber Russell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrew S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Patrick Mathews",
        "service_type": "Garage Door Insulation",
        "tech_name": "Eileen K.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Thomas White",
        "service_type": "Garage Door Services",
        "tech_name": "Bradley W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Samuel Boyle",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ralph T.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Russell Anderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Matthew L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Julie Lang",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Steven M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Connie Kelly",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tamara M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Thomas Santiago",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tiffany T.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Mrs. Lisa Kramer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Edward B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Patrick Foster DVM",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mark B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Melanie Blankenship",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kayla W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Mrs. Tina Huff",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kathleen L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Francisco Tran",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Keith M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "David Hull",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Melissa Wall",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Elijah Miller",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Zachary W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Trevor Tate",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Timothy A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Andrew Dalton",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Elizabeth F.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Donna Webster",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Hayley L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Sandy Bartlett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Earl P.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Sandra Lewis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patrick S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Deborah Phillips",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jackson C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Megan Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melinda M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Anthony Dean",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Larry Watson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jessica B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Michele Lam",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Derrick G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Jose Harrison",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Heather B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Karen Swanson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Emily E.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Jon Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kelly J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Joshua Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Shaun B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Sonya Andrade",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amanda M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Michael King",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Susan O.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Donald Schroeder",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Colleen S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Dylan James",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kayla M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "David Watkins",
        "service_type": "Garage Door Services",
        "tech_name": "Julie S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Gregory Deleon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicholas J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Alexis Fuller",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eric B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Penny Baker",
        "service_type": "Garage Door Opener",
        "tech_name": "Roy W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Stephen Robinson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cassandra B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Jodi Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicole S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Ruth Evans",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Adam Gutierrez II",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Amy Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brooke S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Kim Garcia",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Matthew C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "John Davis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Haley H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Jay Garner",
        "service_type": "Garage Door Installation",
        "tech_name": "Debbie D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Jade Romero",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Allison K.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Christine Bentley",
        "service_type": "Garage Door Services",
        "tech_name": "Daniel M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Sara Day",
        "service_type": "Garage Door Services",
        "tech_name": "Shawn S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Linda Vasquez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ashley T.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Jennifer Thomas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Charles R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Jennifer Sanders",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cindy S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Amanda Hall",
        "service_type": "Garage Door Insulation",
        "tech_name": "Matthew S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Donald Patterson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tony B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Rachel Jackson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Natasha M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Robin Lane",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jason W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Angela Baldwin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Craig S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Antonio Todd",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Jessica Newton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kevin C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Regina Torres DVM",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Larry H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Erica Morse",
        "service_type": "Garage Door Insulation",
        "tech_name": "Samantha S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Frank",
        "service_type": "Garage Door Services",
        "tech_name": "Christina W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Greg Gould",
        "service_type": "Garage Door Off Track",
        "tech_name": "Douglas H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Alexandra Gonzalez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Mark Reed",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lauren F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Jordan Gonzales",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dakota M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Brian Scott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Cheryl G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Amanda Meyer",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert O.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Andrew Boone",
        "service_type": "Garage Door Opener",
        "tech_name": "Jamie C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Samuel Morgan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Valerie S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Carlos Gordon",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christine N.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "John Gray",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Philip E.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Raven Fry",
        "service_type": "Garage Door Opener",
        "tech_name": "Mary D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Juan Pugh",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Manuel Edwards",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Matthew R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Lucas Holland",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Sara Maynard",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amber B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Kimberly Anderson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Grace Gonzalez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joshua M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Kenneth Hoffman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Xavier S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Jessica Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Martin C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Suzanne Carey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Steven W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Alexis Fleming",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alex G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Matthew Werner",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mark B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Christopher Miller",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "John Allen DDS",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Randy Miller",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Thomas P.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Amy Hunter",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kenneth A.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Thomas Patterson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica K.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "George Martinez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Michael Steele",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kim H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Chad Kaiser",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mason N.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Natalie Shelton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Roger N.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Tiffany Serrano",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Peter A.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Robert Oconnor",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicholas C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Hayden Banks",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Rachel H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Alvin Flores",
        "service_type": "Garage Door Repair",
        "tech_name": "Brandy T.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Christina Allen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Deborah L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Nathan Cook",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Ryan Mills",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Karen M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Michael Dennis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sarah K.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Tracy Khan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Maria M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Catherine Glover",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Wanda J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Patrick Walker",
        "service_type": "Garage Door Services",
        "tech_name": "Deanna T.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Natalie Cook",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mary M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Kristen Baker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Wesley C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Patricia Mathews",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ryan Y.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Jessica Weaver",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Maria K.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Kimberly Moss",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Stacy Carr",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert G.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Steven Rodriguez DDS",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ivan H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Morgan Turner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tony B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Julie Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christina G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Jeremy Robinson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Todd R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Anthony Allison",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Justin C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Amanda Donaldson",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher N.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Christian Richard",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bridget C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Renee Gilbert",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lori G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Alexander Thompson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Joel Gonzalez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Erin M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Mrs. Lindsay Spencer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Clifford Rogers",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nathaniel T.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Calvin Fletcher",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sara M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Angel Salinas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Patrick Jenkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jerry C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Kevin Clements",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Jay Williams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Andrea Lee",
        "service_type": "Garage Door Opener",
        "tech_name": "Benjamin R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Rebecca Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tina H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "James Jackson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristine C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Mary Stewart",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Douglas R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Kathryn Davis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Melissa T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Katelyn Lee",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Yesenia G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Wendy James",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Stacy M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Joseph Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Katrina M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Richard Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kristin B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Ronald Rose",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Erin M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Jeremy Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Marc S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Joshua Cooke",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cynthia R.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Danielle Diaz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Peter Jones",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mark M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Kelsey Hardy",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rodney A.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Dorothy Ballard",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Linda M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Jonathan Butler",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Carolyn Brock",
        "service_type": "Garage Door Repair",
        "tech_name": "Kristin P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Tracie Howard",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Laura H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Mrs. Vanessa Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "James G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Robert Chaney",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Samuel E.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Gina Soto",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shawn M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Mr. Jonathan Gallagher DDS",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Leslie V.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Isaiah Vazquez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Richard B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Sarah Marquez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Angela F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Linda Perez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Patty B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Wanda Douglas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jose M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Riggs",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christine J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Craig Thomas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Joseph Hubbard",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David E.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Roger Young",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Randy M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Brandon Solomon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Clayton S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "John Rodriguez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tara T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Rachel Lane",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kerry B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Aaron Brown",
        "service_type": "Garage Door Insulation",
        "tech_name": "Vicki S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Michael Erickson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mary B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Abigail Torres",
        "service_type": "Garage Door Installation",
        "tech_name": "Victor R.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Jason Gibson MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brent R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Lauren Stevenson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Martin D.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Cynthia Carter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephanie R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Randy Lee",
        "service_type": "Garage Door Installation",
        "tech_name": "Brenda J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Laura Baker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sara K.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Taylor Gilmore",
        "service_type": "Garage Door Installation",
        "tech_name": "Kayla C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Angela Randolph",
        "service_type": "Garage Door Installation",
        "tech_name": "Lauren H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "George Diaz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sharon H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Larry Alvarado",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tasha H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Carl Kline",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Greg Payne",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Cindy S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Lauren Lee",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Daniel C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Matthew Glass",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kenneth M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Ryan Perez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kevin M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Luis Rogers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ethan L.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Brittany Mcdonald",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kimberly S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Sabrina Dixon",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Tasha Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeffery M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Alexander Preston",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nancy S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Robert Murphy",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Meagan H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Louis Mahoney",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Bobby B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Daniel Rowe",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ronald C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Lindsay Stanley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kenneth A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Abigail Lopez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Angela H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Mary Gonzales",
        "service_type": "Garage Door Services",
        "tech_name": "Jason C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Joshua Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mark P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Chad Proctor",
        "service_type": "Garage Door Services",
        "tech_name": "Susan R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Mark Murphy",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alexander K.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Sara Figueroa",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kaitlyn W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Gary Richardson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christy C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Anthony Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Sara P.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Willie Decker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Troy R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Susan Beard",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jenna M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Robert Ramos",
        "service_type": "Garage Door Installation",
        "tech_name": "Seth J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Karen Hobbs",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Craig A.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Amber Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Victoria H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Mark Murillo",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Richard C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Nancy Odom",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer D.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Ashley Christensen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Steven H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Bailey James",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephen S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Christine Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Edward H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Angela Lowe",
        "service_type": "Garage Door Opener",
        "tech_name": "Joel M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Steven Nguyen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daniel D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Joel Roberts",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Michael Miles",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Bryan W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Amanda Steele",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shawna B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Charles Adams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Steven H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Sarah Morgan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Madison M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Kimberly Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Angela V.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "David Washington",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicole J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Daniel S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "David Day",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jamie J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Carl Sellers",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Frederick H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Justin Kirby",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sarah J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Adam Solis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melissa B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Meagan Pearson",
        "service_type": "Garage Door Installation",
        "tech_name": "Michele N.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Jason Taylor",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brittany J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Justin Moreno",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Natalie R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Gregory Abbott",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carrie P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Justin Cox",
        "service_type": "Garage Door Services",
        "tech_name": "Lynn C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Richard Frazier",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ronald H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Donald Erickson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael N.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Richard Aguirre",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael E.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Suzanne Collins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Peter C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Jackie Gonzalez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Scott B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Ralph Zavala",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Christopher Martinez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eric W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Jason Rose",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sandra G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Justin Santos",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Nicole White",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Erik F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Patricia Huber",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brandon K.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Robert Aguilar",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nathaniel H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Henderson DVM",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amy G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Kelsey Ortiz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mark B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Kim Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Hailey Norton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ryan W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Patrick Dickson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mark S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Nathan Watson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rebecca M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Nicholas Goodman MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Sarah Wilson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ronald D.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Tiffany Fry",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brian C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Travis Wright",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jackson H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Steven Welch",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ashley W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Annette Ellis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amy A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Jeremy Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ruth J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Miss Rachel Myers",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cathy H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Jacob Miller",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brian P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Erica Gonzalez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ian D.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Thomas Gordon",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mary P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Michael Nguyen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tiffany M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Jay Woodard",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Connor P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Scott Fox",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Katrina F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Brandon Guerrero",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gregory N.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Desiree Fisher",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sharon C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Sharon Rodgers",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lori B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Mrs. Margaret Walsh",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Adam M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Robert Walters",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Rachael C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Lisa Vazquez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Andrea W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Jennifer Martinez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ernest C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Stephanie Martin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Maria H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Brandon Holt",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Vanessa M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Gregory Miles",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James T.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Barbara R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Randall Hudson DDS",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ashley S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Brian Ryan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sarah N.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Ethan Griffin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Steven S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Benjamin Rasmussen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alexander J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Lisa Caldwell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melissa L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Hess",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Logan G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Jonathan Patel",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin E.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Michael Hoover",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Laura F.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Michael Harmon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Jordan Richmond",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Maureen B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Samantha Valencia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Melinda Hamilton",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joshua R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Debra Carter",
        "service_type": "Garage Door Installation",
        "tech_name": "Adam L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Monica Thompson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Donna E.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Samuel Rodriguez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tim B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Nina Dyer",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rebecca J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Amanda Brooks",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Savannah L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Ronald Lopez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jacob D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Alexander Brady",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Mary Morrison",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Shawn T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Janet Jones",
        "service_type": "Garage Door Services",
        "tech_name": "Dakota S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Lisa Jones MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Priscilla P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Victoria Rogers",
        "service_type": "Garage Door Repair",
        "tech_name": "David W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Sarah Duncan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Samuel Powers",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andres C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Jennifer Lee",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christian H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Cindy Allen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ann R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Andrea Patel",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Brandy Hooper",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joshua R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Daniel Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Deanna B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Justin Pace",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ashley J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Thomas Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cindy L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Kelly Morton",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Mr. Brian Heath",
        "service_type": "Garage Door Installation",
        "tech_name": "Gina C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Austin Cruz",
        "service_type": "Garage Door Repair",
        "tech_name": "James G.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Corey Shaffer",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Megan Ramirez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "April P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Sandra Fisher",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Linda R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Melvin Jackson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jamie C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Caleb Gaines",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joshua J.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Joshua Clark",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kristen C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Susan Davis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Cory B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Kristen Gordon",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amy P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Rachel Ingram",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Stephanie T.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Hanson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "April W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Anthony Lopez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lauren M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Patricia Daniels",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Manuel Patrick",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jade F.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Emily Kim",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Steven K.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Christopher Small",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Diana W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "David Campbell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Christopher Fields",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jonathan D.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Daniel Fisher",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Valerie R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Renee Foster",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Suzanne C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Brian Bennett",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brandon A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Daniel Atkinson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Annette M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Daniel Ford",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kendra F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Morgan White",
        "service_type": "Garage Door Services",
        "tech_name": "Kathryn M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Tanya Moreno MD",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michelle R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Ricardo Bowen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Reginald J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Heidi Mendoza",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "William Gibbs",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kenneth P.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Manuel Willis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Deanna C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Keith Walker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stacy C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Anthony Robinson",
        "service_type": "Garage Door Opener",
        "tech_name": "Maria S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Gloria Burnett",
        "service_type": "Garage Door Installation",
        "tech_name": "Marcus B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Tina Hanna",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Adam A.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Shannon Gonzalez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Jessica Murray",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua O.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "William Glenn",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Debra G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Jonathan Jacobs",
        "service_type": "Garage Door Off Track",
        "tech_name": "Philip L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Brittany Baker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Dale B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Stephanie Allen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert K.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Amy Wyatt",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Aaron H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Samuel Best",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Heidi W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Curtis Lamb",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jacqueline W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Mrs. Brandy Arias DDS",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bradley W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Nathan West",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Cheryl T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Antonio Nunez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christine G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Anthony Allen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Justin J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Randy S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Marc Mclaughlin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joshua N.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Hannah Merritt",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lynn T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Stephanie Carter",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Thomas Mcdowell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joshua L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Charles Holt",
        "service_type": "Garage Door Repair",
        "tech_name": "Hector C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Cynthia Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephen R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Annette Gray",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Ronald Anderson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kathy M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Philip Freeman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Edwin Gonzalez",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Rebecca Mcintyre",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David D.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Alexis Franklin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Catherine J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Gregory Willis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shannon C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Linda Hall",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Taylor B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "George Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Timothy B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Autumn Scott",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amy H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Michael Calhoun",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Courtney V.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Gregory Sparks",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ashley M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Deanna Cruz",
        "service_type": "Garage Door Repair",
        "tech_name": "Kayla L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Brian Gill",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael T.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Lisa Medina",
        "service_type": "Garage Door Installation",
        "tech_name": "Carrie J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Derrick Knox",
        "service_type": "Garage Door Installation",
        "tech_name": "Robin C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Shelby Oneal",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melissa B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Darryl Roberson",
        "service_type": "Garage Door Installation",
        "tech_name": "Manuel F.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Matthew Watson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Charles W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Madison Wells",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kathryn G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "James Welch",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kathleen Y.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Thomas Vasquez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Crystal Ross",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Crystal R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "James Brennan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Peggy Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lydia B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Dominic Bates",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Timothy B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Zachary Hill",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Elizabeth B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Cynthia Watson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Beth M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Megan Farmer",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Latasha D.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Roberta Stevens",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie A.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Anna Garcia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Philip M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Andrea Roman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicole S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Alejandra Horn",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Whitney M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Wendy Warren",
        "service_type": "Garage Door Installation",
        "tech_name": "Stephanie M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Richard Young",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Howard G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Jared Berry",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alexis A.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Wendy Owen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Darrell Reed",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Daniel B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Tina Haynes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Madison H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Drew Shepard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gerald C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Brandy Martin",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anthony S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Karen Roberts",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Bailey Lewis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dawn S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Heather Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Judy M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Teresa Newton",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christopher K.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Amy Newman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Dylan F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Kenneth Nielsen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anna W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Jason Barnett",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mark W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Olivia Christian",
        "service_type": "Garage Door Off Track",
        "tech_name": "Shannon H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Daniel Guzman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ruben W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Lisa Mitchell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michelle D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Martha Brown",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Heidi L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Cynthia Scott",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rachel S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Walter Foster",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rachel G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Peter Robinson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amy S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "James Wilson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Denise L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Carolyn Graham",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lisa R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Steven Reed",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Kurt Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mark C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Christopher Bean",
        "service_type": "Garage Door Repair",
        "tech_name": "Becky L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Regina Hoover",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Martin B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Anthony Brown",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sherry G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Richard Butler",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tanner F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Melissa Reilly DDS",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sarah R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Jamie Bell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alison C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Ms. Ana Schwartz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Randy W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Roberto Rowe",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stephanie W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Douglas Perez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Justin Larson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Jonathan Duncan",
        "service_type": "Garage Door Repair",
        "tech_name": "Gregory H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Tasha Jackson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Leroy Freeman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Katelyn K.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Terry Berg",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jacob D.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Lori Kramer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Travis L.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Billy Walters",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Derrick M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Christopher Hodge",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mary R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Mr. Logan Jones DVM",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Mrs. Alexandria Mayer",
        "service_type": "Garage Door Opener",
        "tech_name": "Beth T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Rebecca Warren",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mark C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Ronald Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Todd N.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Michaela Harris",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Marcus C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Meredith Farmer",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mary S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Steven Coleman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Taylor",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Danielle M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Tracy Ramos",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Daniel S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Lisa Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carrie E.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Alyssa Escobar",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher T.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Crystal Berger",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Denise R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Christine Duke",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Emily Adkins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jacqueline R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Kim Wilson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Elizabeth D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Garrett Riley",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Todd B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Casey Thompson",
        "service_type": "Garage Door Installation",
        "tech_name": "Kenneth C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Amy Griffin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andre J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Chase Cruz",
        "service_type": "Garage Door Installation",
        "tech_name": "Carrie Y.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Sydney Williamson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Vincent G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Anthony Walsh",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lindsey M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Holly Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jessica R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Christina Sanders",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Katelyn C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Brendan Andrews",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ryan O.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Shannon White",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Daniel P.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Michael Ruiz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Andrea W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Ana West",
        "service_type": "Garage Door Opener",
        "tech_name": "Eric E.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Desiree Arnold",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jose G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Brandon Lewis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donald A.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Jason Rodriguez",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Christine Stafford",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gabrielle F.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Andrew Burton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jacqueline M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Emily Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brenda S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Mrs. Meghan Rose MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Donald G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Brandi Lam",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melanie C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Thomas James",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brian G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Sara Haynes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "John B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Michelle Kelley",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bryan H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Thomas Evans",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tina T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Melissa Norman",
        "service_type": "Garage Door Services",
        "tech_name": "Robert S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Jill Carpenter",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Howard J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Andrew Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "Sandra R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Anthony Brown",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael A.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Sandra King",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Grace H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Ashley Payne",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicole H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Norma Lopez",
        "service_type": "Garage Door Services",
        "tech_name": "Darius B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Leah Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Richard S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Shannon Nichols",
        "service_type": "Garage Door Installation",
        "tech_name": "Theresa M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Mark Taylor",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Sara Salazar",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Traci R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Kristy Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Julie G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Jason Santiago",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Paul J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Andrew Schultz",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Robert Garcia",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "John S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Coleman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Nathan Miller",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jose C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Michael Wise",
        "service_type": "Garage Door Services",
        "tech_name": "Robert V.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Austin Stark",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Maurice A.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Harold Patrick",
        "service_type": "Garage Door Repair",
        "tech_name": "Peter R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Tina Schmidt",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "James Allen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Phyllis C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Mrs. Mary Porter",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Dr. Matthew Villegas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Catherine F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Tonya Swanson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Julie P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Kari Ward",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Calvin H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Danielle Taylor",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephen S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Traci Perry",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Chris M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Terry Gaines",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lori B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "John Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Keith F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Renee Green",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephanie M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Nicholas Tran",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kevin P.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Andrew Wilson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Julie R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Paul Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tanya S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Dr. Susan Phillips PhD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Chad T.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Heather Ruiz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeffrey H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Troy Arellano",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Marc W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Derek Alexander",
        "service_type": "Garage Door Off Track",
        "tech_name": "Samuel G.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Mata",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jo N.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Hunter Phillips",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Thompson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Charles Shaffer",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Molly L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Rebecca Mejia",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert K.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Alex Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Helen W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Stephanie Vargas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rebecca P.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Nicole Knox",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Carrie Foster",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Roberto Zimmerman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sarah F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Molly Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrea H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Kelly Adams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kevin K.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Jennifer Mckinney",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Paige S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Jennifer George",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tammy W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Kristen Lowe",
        "service_type": "Garage Door Installation",
        "tech_name": "Crystal J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Laura Grant MD",
        "service_type": "Garage Door Repair",
        "tech_name": "James M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Adriana Lam",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Charles C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Kevin Marquez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Erica Burch",
        "service_type": "Garage Door Repair",
        "tech_name": "Maria R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Stephanie Klein",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Raymond S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Kathleen Pratt",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christina G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Thomas Romero",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cindy P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Danielle Coleman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Valerie N.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Jason Mitchell Jr.",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Richard D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Pamela Cohen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Whitney M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Yesenia Palmer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Diana M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Tamara Moore",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Denise G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Amy Perez",
        "service_type": "Garage Door Installation",
        "tech_name": "Jose A.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Evan Young",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Samuel M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Carol Rodriguez",
        "service_type": "Garage Door Services",
        "tech_name": "Natalie R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Sarah Adams",
        "service_type": "Garage Door Services",
        "tech_name": "David R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Edward Young",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Barbara G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Paul Jackson",
        "service_type": "Garage Door Opener",
        "tech_name": "Jamie F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Nancy Stewart",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Troy C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Scott Walker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jacqueline B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Laurie Lynch",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Jennifer Bridges",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cory M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Taylor Bennett",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kristie S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Sheila White",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel F.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Marc Flores",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sandra J.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "James Anderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Carolyn H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Wendy Gilbert",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph D.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Dr. Nicholas Douglas",
        "service_type": "Garage Door Opener",
        "tech_name": "Daniel J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Luis Spence MD",
        "service_type": "Garage Door Opener",
        "tech_name": "Heather W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Raymond Walker",
        "service_type": "Garage Door Installation",
        "tech_name": "Karen F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Travis Duke",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Scott K.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Mr. Michael Gregory MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Frederick J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Sherri Jones",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sarah M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Chad Jacobson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lori W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "David Wagner",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Tina Le",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey N.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Carmen Moore",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ricardo R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Kimberly Wilson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ronald F.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Melanie Bradley",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Caitlin M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Kaitlyn Horton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Douglas C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Craig Eaton",
        "service_type": "Garage Door Repair",
        "tech_name": "Cynthia W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Dr. Traci Williamson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James L.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Jose Gay",
        "service_type": "Garage Door Services",
        "tech_name": "Karen C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Kyle Wilson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Charles J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Thomas Moore",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kayla H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Andrew Jackson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brandi C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Juan Wilson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tina G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Elizabeth M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "James Pace Jr.",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Erin N.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "John Hickman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Douglas M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Mariah Boone DVM",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christina S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Peter Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kenneth S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Abigail Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Jasmine Russell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Spencer H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Maria Dean",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Kim Lester",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Dixon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Charles F.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Kevin Lewis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gary L.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Marie Murray",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Heidi Snyder",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amy H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Kevin Cole",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joshua J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Leslie Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jack H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Anne Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Juan N.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Tammy Dixon",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carrie R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Christopher Atkins",
        "service_type": "Garage Door Opener",
        "tech_name": "Dale S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Jason Villarreal",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sandra R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Kelly Parker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ashley S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Leslie Marks",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Sharon Dawson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Angela P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Joe Boyer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Patrick Harris",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Beth R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Erin Mendez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Chase S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Frank Schultz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Helen P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Joseph Hall",
        "service_type": "Garage Door Services",
        "tech_name": "Caroline G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Adam Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kelli T.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "James Allen II",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sarah L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Ashley Hunter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Matthew Kennedy",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gregory S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Martha Sanchez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Ann Cuevas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Renee Stout",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christian K.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Nathaniel Woodard",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kevin P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Michael Long",
        "service_type": "Garage Door Installation",
        "tech_name": "Joel W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Donna Webster",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jim B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "William Crawford",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Brett Neal",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christina C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Alejandro Jenkins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Miguel A.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Paul Martinez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rebecca R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Joshua Long",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jared H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Patricia Wright",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bryce J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Chad Wilson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Samantha R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Peter Mendoza",
        "service_type": "Garage Door Services",
        "tech_name": "Melvin R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Carrie Perkins",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Miranda M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Robert Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paula L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Kevin Villa",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stephanie G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Shane Rodriguez",
        "service_type": "Garage Door Services",
        "tech_name": "Jacob M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Gordon Allen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michelle S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Rose Hall",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jason S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Teresa Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Heather R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Raymond Ponce",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kayla B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "John Gray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bradley S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Christopher Palmer",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tommy B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "William Barr",
        "service_type": "Garage Door Opener",
        "tech_name": "Jimmy Y.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Scott Shaw",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Joseph Graham",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ray J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "David Anderson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brett H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Gordon Lin",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Chris G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Mark Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Taylor S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Laura Stevens",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shannon J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Anita Henderson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Zachary T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Colleen Gonzalez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Russell M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Jasmine Jackson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lynn F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Jessica Lambert",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jeffrey C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Stacy Sloan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ryan M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Zachary Stewart",
        "service_type": "Garage Door Opener",
        "tech_name": "William B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Nicholas Allen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Miranda K.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Renee Mathews",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jessica G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Tracy Rose",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Justin B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Steven Parks",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Charles P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Erik Caldwell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Heidi W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Ronnie Blake",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Carolyn Spears",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brad T.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Sarah Bell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Kimberly Coleman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joseph S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Anthony Brewer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brandon T.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Steven Wagner",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Vincent B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Mrs. Pamela Kim",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Katherine A.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Melissa Hunt",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrew C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Vanessa Santiago",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ryan B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Douglas Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Loretta J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Johnathan Juarez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lisa K.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Jonathan Walker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Amanda King",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Angel A.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Kenneth Thompson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Timothy C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Derek Moore",
        "service_type": "Garage Door Opener",
        "tech_name": "Krista P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Katie Wright",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Patrick L.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Edwin Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jenny D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Alexandra Nguyen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kimberly L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Joseph Allen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Eric K.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Cindy House",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Karen B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Sarah Bray",
        "service_type": "Garage Door Repair",
        "tech_name": "Tyler P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Devon Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Calvin C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Hunter Graves",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Paul Fritz",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sara C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Charlene Coleman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Maureen R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Laura Reynolds",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Derrick H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Patricia Jensen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Desiree M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Zachary Bradford",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Noah A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Adam Black",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lauren W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Adams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Tammie Costa",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Wanda Mason",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Benjamin Small",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mark A.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Samantha Randolph",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kimberly J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Jeremy Morton",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael T.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Brandon Farley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Wanda H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Kelly Campbell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jim S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Kristin Mcgee",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Andrew Sparks",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Chad Mcclain",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Briana L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Nicole Copeland",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Daniel P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Rebecca Chavez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Parker R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Victoria King",
        "service_type": "Garage Door Repair",
        "tech_name": "Monique T.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Dillon",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Roger J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "William Richards",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "George R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Thomas Blackburn",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Sheila Reyes",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jimmy C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Emily Knight",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Samantha Mcgrath",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Elizabeth L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Maria Bryan",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Lauren Gray",
        "service_type": "Garage Door Repair",
        "tech_name": "Marc L.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Amber Morrison",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Amy Davis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jane M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Autumn Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Dorothy Tapia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lynn F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Donna Diaz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Ryan Rodriguez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dorothy R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Dustin Hale",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Felicia Sanchez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Kelly Morris",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amy G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Alexander Hunter",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tara P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Nancy Rodriguez",
        "service_type": "Garage Door Services",
        "tech_name": "Katherine W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Gabriel Rodriguez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brandon S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Katelyn Brennan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brian B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Kimberly Lang",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bradley G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Jeremy Munoz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Eric Wood",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ashley S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Peter Nguyen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Gregory M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Michael Martinez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Henry M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "John Valentine",
        "service_type": "Garage Door Insulation",
        "tech_name": "Catherine G.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Joseph Garcia",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kendra W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Ebony Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Samuel H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Robert Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gina M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Melissa Navarro",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lisa H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Mr. Cameron Morris IV",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rita D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Deanna Ho",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tammy M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Stephanie Mcpherson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sandra S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Gabriel Lee",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Evelyn H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Beth Newman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Katie Foster",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ruben M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Ashley Rodriguez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Martin H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Sara Benitez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Daniel Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kimberly M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Suzanne Collins",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Raven S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Deborah James",
        "service_type": "Garage Door Insulation",
        "tech_name": "Teresa O.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Nicole Berry",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nancy D.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Brent Carpenter",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Henry V.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Victoria Gomez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica Y.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Sandra Wheeler",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Morgan F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Ryan Dyer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Adrienne M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "John Conley",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elizabeth H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Martin Contreras",
        "service_type": "Garage Door Insulation",
        "tech_name": "Carol H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Diane Moore",
        "service_type": "Garage Door Installation",
        "tech_name": "Nathan H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Laura Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alicia A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Sandra Weber",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brenda H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Stacy Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Paul H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Taylor Lang",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Eric Garcia",
        "service_type": "Garage Door Repair",
        "tech_name": "Ray M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Nicole Coleman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Barbara G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Melanie Gomez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Karen Mendez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Chad C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Kimberly Harris",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bryan F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "John Fisher",
        "service_type": "Garage Door Opener",
        "tech_name": "James A.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Henry Morales",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Marissa W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Berger",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sarah M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Mrs. Sherri Palmer",
        "service_type": "Garage Door Repair",
        "tech_name": "Melissa J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Christian King",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Richard W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Mrs. Samantha Chavez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lee B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Daniel Black",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Hannah A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Angel Arnold",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mary B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Terry Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Destiny B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Tiffany Brown",
        "service_type": "Garage Door Services",
        "tech_name": "Teresa S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Stacy Robinson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melvin G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "George Harvey",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jasmine A.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Jason Clarke",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Karen A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Steven Brown",
        "service_type": "Garage Door Opener",
        "tech_name": "Shawn C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Adriana Hawkins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Clarence M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Daniel Day",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sarah R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Traci Obrien",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alfred B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Jason Gray",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Meghan Y.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Troy Hughes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jasmine H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Alex Lindsey",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Deanna T.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Mary Patel",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Bernard M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Robert Roy",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Travis W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Sean Ponce",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cynthia K.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Stephanie Sullivan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Linda S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Lindsey Johnson DVM",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joseph J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Steven Solomon",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Denise R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Robert Davis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Erika C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Tiffany Velasquez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brenda R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Austin Rodriguez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Lindsay David",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Russell C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Brian Mejia",
        "service_type": "Garage Door Services",
        "tech_name": "Victor W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Alyssa Murray",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alicia W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Katie Wise",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Jason Martin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Zachary K.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Robert Gregory",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jacob A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Ann Robinson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Monica P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Kimberly Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Damon Coleman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicole M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Robert Walsh",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Kristina Edwards",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anthony M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Daniel Palmer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Rodney Hughes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cynthia L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Shannon Evans",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rebekah W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Lauren Mcfarland",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Kevin Wilkins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kristina J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Billy Gordon",
        "service_type": "Garage Door Services",
        "tech_name": "Robert C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Mr. Mark Garcia",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joann H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Tyler Jones PhD",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jocelyn M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Amanda Morris",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Debra M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Steven Allen",
        "service_type": "Garage Door Services",
        "tech_name": "Dawn H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Brian Hudson",
        "service_type": "Garage Door Services",
        "tech_name": "Marvin R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Robert Mckinney MD",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Douglas F.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Deanna M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Jane Bailey",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Donald S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Lopez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ryan H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Bradley Clarke",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jose D.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Cheryl Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Mallory L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Michael Hayden",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kelly S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Jerry Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ryan M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Evan Lucas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Miss Maria Singleton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Samantha M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Dorothy Gaines",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Natalie H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Derek Mendoza",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Felicia G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Eric Graves",
        "service_type": "Garage Door Opener",
        "tech_name": "Maureen S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Tina Ellison",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joe F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Kaitlyn Price",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anna M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Aaron Tyler",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cody P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Taylor",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shelley K.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Joseph Williams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Oscar B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Mary Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jessica C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Erika Green",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ryan W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Jonathan Rosario",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kimberly K.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Allison Jacobson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Amy Williamson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Daniel B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Natalie Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Barry S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Ruth Li",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Deborah W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Tony Gibson",
        "service_type": "Garage Door Services",
        "tech_name": "Douglas G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "James Mendoza",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melody A.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Sally Lowe",
        "service_type": "Garage Door Opener",
        "tech_name": "Edward J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Wendy Riley",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Renee L.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Thomas Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Heather Mcdonald",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ryan M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Robert Vargas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "George Baldwin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephen B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Howard Collins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Eduardo Parks",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Paige C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Christie Stone",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brian C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Laurie Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Carlos W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Erin Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer T.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Bryan Sanchez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Barbara Gallagher",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christian G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Jill Jensen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephanie C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Casey Green",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Craig S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Jorge Richardson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy T.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Crystal Lewis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrea M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Kelsey Castaneda",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "James R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Amber Taylor",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John N.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Theresa Ruiz",
        "service_type": "Garage Door Repair",
        "tech_name": "Elizabeth W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Paula Mitchell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Katherine M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Joyce Allen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Charles Lutz",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amber M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Dillon Lewis",
        "service_type": "Garage Door Opener",
        "tech_name": "Donald A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Brandon Bryant",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Laurie Cooper",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lance J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Samuel Sherman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Frank M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Michael Parker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Diana S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Kaitlyn Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nathan M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "David Moore",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kyle H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Kathleen Cooper",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rachel H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Tanya Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "Laura R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Jason Pena",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kristie S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Sydney Anthony",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Gregory Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Pamela B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Leslie Hill",
        "service_type": "Garage Door Insulation",
        "tech_name": "Paul L.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Ralph Huynh",
        "service_type": "Garage Door Opener",
        "tech_name": "Scott O.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Brenda Waters",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Patricia F.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Robert Warren",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Charles Z.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Michael Le",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sara G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Jeremy Mahoney",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rachel Z.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Antonio Hickman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Isaac H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Ashley Vazquez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Crystal O.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Mrs. Janice Hamilton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Patty K.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Ralph Hopkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jillian B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Lisa Anderson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katrina E.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Timothy Pham",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Riley M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Jacob Torres",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andrea A.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Carla Boone",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Linda Z.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Wendy Jackson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Wayne M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Stephen Ward",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "William M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Carolyn Berry",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Karen S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Rachel Anderson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Anita S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Kathryn Barber",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Renee S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Kevin Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Caitlyn Leblanc",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Katelyn W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Paul Mitchell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Linda C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Xavier Turner",
        "service_type": "Garage Door Installation",
        "tech_name": "Karen W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Allen Tran",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bradley D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Heather Powell",
        "service_type": "Garage Door Services",
        "tech_name": "Elizabeth H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Charles Hutchinson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Nicholas Evans",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Timothy Williamson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Samuel R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Suzanne Jackson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brandi P.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Richard Forbes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tiffany R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Alex Logan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher Z.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Lori Tanner",
        "service_type": "Garage Door Opener",
        "tech_name": "Alyssa B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Michael Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Joe Hicks",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephen J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Andrea Harris",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kathy K.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Meghan Freeman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sara O.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Randy Thompson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kara S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Robert Cross",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amy W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Mclaughlin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Connor J.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Tristan Kane",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James D.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Stephen Martin",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nancy L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Garrett Goodwin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Antonio S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Stephanie Phillips",
        "service_type": "Garage Door Installation",
        "tech_name": "Kelly J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Randall Barry",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lindsey F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Ashley Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Corey A.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Spencer Rogers DDS",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brittany W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Jason Perez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Katherine S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Ms. Kristina Shaw",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Colin H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Cheryl Perry",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cindy C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Christopher Rogers",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ashley H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Michael Ramirez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Heather M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Lisa Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Joshua Wilson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Ann Nguyen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Melissa Soto",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Donald Z.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Tara Dennis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Derrick J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Jennifer Lee",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Katrina Huynh",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kyle B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Matthew King",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nathan J.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Teresa Coleman",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Matthew Brown",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amber H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "James Jennings",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David D.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Jennifer Ponce",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anthony P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Amy Bennett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Wallace",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lori G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Danielle Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amanda L.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Tim Farrell",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "John Ford",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Meghan F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Francisco Richardson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Carrie Shaw",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Larry T.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Jessica Daniel",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeffrey W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Lynn Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brittany C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Barbara Frazier",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Miranda D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Angel Conley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Derek A.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Kristy Hill",
        "service_type": "Garage Door Installation",
        "tech_name": "David P.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Taylor Rush",
        "service_type": "Garage Door Services",
        "tech_name": "Scott B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "David Tran",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ronald B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Alexander Perry",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erik M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Derek Morrison",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ellen P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "James Simpson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jason M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Christopher Gibbs",
        "service_type": "Garage Door Services",
        "tech_name": "Michelle P.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Robert Jennings",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ryan W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Haley Park",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Destiny J.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Deborah Williamson DDS",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Keith W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Chad Payne",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tanya T.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Melissa Martin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kim C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Marcus Martinez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Douglas R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Kathryn Roberts",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Barbara B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Rachel Whitney",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ryan F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Tina Thomas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kara N.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Brooke Knight",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Patty B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Joseph Molina",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jacob S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Jose Swanson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Maria H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Carla Newman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Evan B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Patrick Mosley",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Linda T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Abigail Daniel",
        "service_type": "Garage Door Repair",
        "tech_name": "Robin F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Kerri Olson",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Courtney Alvarado",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alyssa P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Mrs. Michele Aguilar MD",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Christine Mosley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael J.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Mike Hood",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Thomas R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "John Conway",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Corey L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Valerie Phillips",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bonnie B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Holly Wagner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Amber Dorsey",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Ronald Duke",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jack B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Brianna Price",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Darryl W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Lori Nunez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Angela H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Mallory Mejia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Derek S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Howell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Joshua James",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tammy H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Lydia Leonard",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Roger Larson",
        "service_type": "Garage Door Services",
        "tech_name": "Michael V.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Rebecca Pruitt",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Marie G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Joseph Ruiz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Dr. Jordan Arnold",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Daniel C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Kaitlin Carlson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Candice P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Nicole Chandler",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joe R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Jim Chandler",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Janet T.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Shane Hines",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Catherine B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Michelle Matthews",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kristin M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Peggy Shields",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nathan M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Anthony Hunt",
        "service_type": "Garage Door Installation",
        "tech_name": "James S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Carol Meza",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kevin M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Brian Petersen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Donald Goodman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rebecca M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Susan Richards",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dawn C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Mr. Todd Brown",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "James Ellis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Tara Day",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shirley B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Beth Jones",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Susan G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Deborah Stephens",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Cheryl N.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Kristina Rivers",
        "service_type": "Garage Door Opener",
        "tech_name": "Justin A.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Jennifer Chen",
        "service_type": "Garage Door Repair",
        "tech_name": "Brett D.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Katelyn Tyler",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Barbara A.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Roberts",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Briana D.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Rhonda Price",
        "service_type": "Garage Door Repair",
        "tech_name": "Patricia I.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Robert May",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "William O.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Christy Sanchez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Shane G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Tracy Adkins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bethany J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Kaitlyn Young",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "David Williamson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Connie H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Cynthia Decker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Samuel W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Dennis Dyer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kenneth E.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Sarah Solomon",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Caroline King",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tiffany D.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Lauren Cook",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Wesley Huynh",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tiffany W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Rebecca Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jay G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Paige Tran",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shannon D.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Ricky Weaver",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lucas B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Morgan Bridges",
        "service_type": "Garage Door Opener",
        "tech_name": "Tanya L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Christopher Freeman",
        "service_type": "Garage Door Repair",
        "tech_name": "April B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Erica White",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jordan S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Erica Martinez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Anthony F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Trevor Bennett",
        "service_type": "Garage Door Repair",
        "tech_name": "Laura T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Eric Powell",
        "service_type": "Garage Door Services",
        "tech_name": "David H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "David Lopez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Holly S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Michael Shepherd",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Timothy M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Heather Skinner",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Yolanda C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Debra Valencia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stacy M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Anna Campos",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Samantha S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "David Thomas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Allison H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Mark Arnold",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Janet Phillips",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Crystal Mccormick",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "David Parker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Victor F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Jennifer Callahan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Benjamin O.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Jimmy Wilson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anthony M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Shannon Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anthony V.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Gabriel Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "Sharon H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Jennifer Gonzalez",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Wyatt Williams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Daniel Andrade",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Courtney D.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Samantha Jimenez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Holly M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Ashley Butler",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kevin Y.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Alec Vargas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Rebecca R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Kathleen Jackson",
        "service_type": "Garage Door Off Track",
        "tech_name": "William E.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Gary Becker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jay W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Nathan Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Marilyn G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Zachary Cline",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Benjamin A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Michelle Nguyen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Dr. Jodi Gutierrez MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Katie S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "John Russo",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Taylor D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Deborah Richard",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Rebekah Benson",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael V.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Andre Calhoun",
        "service_type": "Garage Door Opener",
        "tech_name": "Mark M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Steven Owen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Vanessa S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "David Reed",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brandon S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Melissa Ward",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joseph B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Bridget Crawford",
        "service_type": "Garage Door Services",
        "tech_name": "Gregory M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Ashley Rivera",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Juan C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Pamela Williams",
        "service_type": "Garage Door Repair",
        "tech_name": "Jeffrey S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Cathy Farley",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kimberly J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Pamela Stewart",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sandra T.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Brian Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brent W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Joseph Quinn",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Scott Meyer",
        "service_type": "Garage Door Services",
        "tech_name": "Andrew W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Donna Singh",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joy M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Joanne Martinez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Justin W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Diana Harris",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Danielle Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ashley B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Eric Martin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amy R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Isabella Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Timothy R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Steve Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Margaret B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Alyssa Snyder",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jonathan C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Lindsey Harvey",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony A.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Katherine Rogers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alexander R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Stephen Li",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amanda P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Angela Garcia",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kyle G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "James Berry",
        "service_type": "Garage Door Opener",
        "tech_name": "David P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Heather Costa",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Austin L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Rebecca Wheeler MD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kenneth J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Albert Turner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Benjamin Rivera PhD",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "April S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Aaron Miller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kristin M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Jonathan Mitchell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Timothy T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Todd Hopkins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alyssa M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Jennifer Clark",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Miguel H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Phillip Dixon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jade T.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Mark Haney",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Shelley P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Austin Ho",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Michael Parks",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "David S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Mary Mathews",
        "service_type": "Garage Door Off Track",
        "tech_name": "Craig C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Tara Chandler",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert O.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "David Rich",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anthony S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Sheila Olson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Zoe B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Charles Bell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Savannah S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Kimberly Harper",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jacob N.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Cole Wilson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Roy J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Jonathan Miles",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sharon T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Daniel Branch",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Laura S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Mandy Jordan DVM",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John L.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Rebecca Ramsey",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Samantha T.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Rachel Weaver",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christine S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "James Ortiz",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Susan M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "David Tanner",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lisa W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "James Swanson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cynthia V.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Toni Howard",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Keith G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Anthony Roberson",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew T.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Danielle Cabrera",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Shane L.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Monica Schwartz",
        "service_type": "Garage Door Installation",
        "tech_name": "Darrell L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Cynthia Castro",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jacob L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Dale Turner",
        "service_type": "Garage Door Installation",
        "tech_name": "Pamela M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Amy Murphy",
        "service_type": "Garage Door Insulation",
        "tech_name": "Eric H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Theresa Daugherty",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nicolas M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Tiffany Robinson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Audrey J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Antonio Ellis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Patricia L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Joseph Wilcox",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cynthia N.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Susan Diaz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rodney W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Brooke Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stacy G.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Pamela Hopkins MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tammy S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Jonathan Espinoza",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dean J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Emily Cherry",
        "service_type": "Garage Door Repair",
        "tech_name": "Joe F.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Jose Kelly",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Julie W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Philip Powell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John E.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Kenneth Goodwin",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amber F.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Paul Holmes",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Mr. Benjamin Hart II",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sheena C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Paul Gray",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kathleen S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Diane Welch",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jaime S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Kelly Moore",
        "service_type": "Garage Door Opener",
        "tech_name": "Sydney B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Amanda Klein",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Samantha W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Kevin Meadows",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ashley Y.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Brian Bowen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Becky Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lisa R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Bright",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicholas A.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Lauren Lynn",
        "service_type": "Garage Door Opener",
        "tech_name": "Katie P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Lori English",
        "service_type": "Garage Door Repair",
        "tech_name": "Jaime W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Patricia Gibson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michelle B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Mario Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Patty W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Diana Freeman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "George Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alisha F.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Jacob Castro",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kendra J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Nancy Sherman DVM",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Gary Hunt",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Natalie Fernandez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joshua M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Francisco Potts",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Jennifer Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Ashley Thomas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sarah S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Ronald Rodriguez",
        "service_type": "Garage Door Opener",
        "tech_name": "Corey D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Daniel Mitchell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christina H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Matthew Martinez",
        "service_type": "Garage Door Services",
        "tech_name": "Gina B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "John Moore",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Angel M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Scott Foster",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jasmine W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Cristina Matthews",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michelle F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Tiffany Evans",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew N.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Patricia Dudley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Erin W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Katie Parker",
        "service_type": "Garage Door Installation",
        "tech_name": "Jeremy S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Mark Edwards",
        "service_type": "Garage Door Repair",
        "tech_name": "George J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Alejandra Hall",
        "service_type": "Garage Door Repair",
        "tech_name": "Charles F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Anthony Robles",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kristina M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Michael Parker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William G.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Derek Dawson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anna H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Christopher Watson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jared N.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "James Snyder",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Victoria P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "William Clark",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Keith S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Linda Daniels",
        "service_type": "Garage Door Opener",
        "tech_name": "Brandon R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Traci Munoz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Danielle J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Travis Savage",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Eric H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Shannon Burton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Colleen C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Brittany Delgado",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jared J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Mrs. Sara Kim",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sheila N.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Larry Bentley",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Virginia R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Jonathan Carroll",
        "service_type": "Garage Door Repair",
        "tech_name": "Craig U.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Christina Gonzalez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Curtis F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "James Reilly",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jeanette R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Kevin Rivera",
        "service_type": "Garage Door Off Track",
        "tech_name": "Derrick D.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Susan Hernandez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Paul H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Dawn Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amanda D.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Charles Cooper",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Steven V.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Kimberly Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Edward W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Melissa Castaneda",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kelly W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Douglas Hall",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Julie S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Thomas Bauer",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Chloe D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Vanessa Francis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ryan O.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Shari Berry",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Heather H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Kathleen Dyer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Johnny M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Holly Perez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Deborah M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Roger Davies",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Frederick R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Rachel Hunter",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mary Y.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Crystal Jenkins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Carolyn P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Kathleen Peterson",
        "service_type": "Garage Door Services",
        "tech_name": "Wendy C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Allison Harris",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kayla R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Michelle Edwards",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Matthew C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Melissa Alexander",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Carolyn Chen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Crystal M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Nicole Garcia",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Natasha W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Timothy Ayala",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Vanessa F.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Alexa Clay",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joanna C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Brittney Morgan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dana R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Jenna Mitchell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Keith I.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Rebecca Weaver",
        "service_type": "Garage Door Opener",
        "tech_name": "Laura J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Joseph Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Vanessa F.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Alejandro Guerra",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kimberly B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Devin Duarte",
        "service_type": "Garage Door Installation",
        "tech_name": "Brittany P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Jessica Ali",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kirk D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "David Marshall",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Adam D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Caroline Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Kyle Tapia",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robin R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Robert Sullivan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ashley J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Tracy Thomas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Travis P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Edward Fuller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Heidi W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "David Payne",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Craig S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Ruth Lowery",
        "service_type": "Garage Door Installation",
        "tech_name": "Virginia M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Jacob Eaton",
        "service_type": "Garage Door Insulation",
        "tech_name": "Heather R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Ashley Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Bruce Mitchell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lindsey R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Shannon Parker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alicia V.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Erin Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jill K.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Albert Wagner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "William Y.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Angela Stone",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jeffrey S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Adam Skinner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brian G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Joshua Villanueva",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kevin C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Jesus Hernandez",
        "service_type": "Garage Door Repair",
        "tech_name": "James H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Kaitlyn Roberson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephanie S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Vicki Cole",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stephen L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Caleb Carpenter",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Susan S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Emily Morris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Whitney C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Brittany Padilla",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alyssa W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Debra Davidson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Walter P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Lisa Coleman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Patricia N.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Tracy Payne",
        "service_type": "Garage Door Repair",
        "tech_name": "Hannah R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Pamela Graham",
        "service_type": "Garage Door Repair",
        "tech_name": "Brian W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Megan Lawrence",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Armstrong",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Debra W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Kristen Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Meghan M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Matthew Lane",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lisa H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Tiffany Robinson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christine P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Sarah Ball",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erica C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Angela Owen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kristin H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Sherri Bryan",
        "service_type": "Garage Door Insulation",
        "tech_name": "David A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Christine Jenkins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michelle R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Carla Rogers",
        "service_type": "Garage Door Installation",
        "tech_name": "David H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Jenny Davidson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Matthew R.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Daniel Daniel",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ellen B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Anthony Simmons",
        "service_type": "Garage Door Services",
        "tech_name": "William T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Brian Hansen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Danielle R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Alvin Franklin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christina A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Henry Gilbert",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mary Y.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Paula Singh",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Andrea Jackson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joseph T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Sara Sullivan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cynthia T.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Nicholas Weber",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gregory G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Robert Morris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ricardo H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Chelsea Jordan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Billy T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Michael Guzman MD",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "John Woods",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Charles H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Andrew Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nancy M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Samuel Hebert",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christina F.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Donald Steele",
        "service_type": "Garage Door Repair",
        "tech_name": "Ryan S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Marvin Hart",
        "service_type": "Garage Door Insulation",
        "tech_name": "Natasha H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Dana Bender",
        "service_type": "Garage Door Services",
        "tech_name": "Shelia A.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Tracy Castro",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer V.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Joseph Kelly",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nancy A.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Wesley Leon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Linda D.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Patricia Price",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Roberta M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Donna Burnett",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kyle N.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Zachary Reid",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Natalie B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Rogers",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Timothy W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Matthew Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Chad P.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "John Stone",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Russell Lewis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robin G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Susan Wong",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Mr. Joshua Park",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Aaron Taylor",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Patrick S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Angel Cruz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Elaine D.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Roger Ward",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Timothy B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Harry Allen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Gina B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Dr. John Kim",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher K.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Don Haynes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Marie Lewis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Albert B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Sean Cunningham",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Leslie B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Anthony Garcia",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Austin B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Debra Medina",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicole W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Virginia Lopez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Linda P.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Stephanie Hobbs",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Alicia Silva",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kathy T.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Betty Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Justin C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Christina Allen",
        "service_type": "Garage Door Off Track",
        "tech_name": "David C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Kevin Higgins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Susan R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Eric Parrish",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Kathryn Silva",
        "service_type": "Garage Door Off Track",
        "tech_name": "Anthony N.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Kimberly Rodriguez DVM",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lisa K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "David Moore",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lance B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Donna Thomas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "William M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Evan Gray",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "William Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Justin V.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Tanya Schultz",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mary S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Rachel Rice",
        "service_type": "Garage Door Repair",
        "tech_name": "Leah W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Robert Mendez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Rebekah Massey",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Heather M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Laura Shaffer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Debra O.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Kerry Fischer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Norma J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Kelly Wilkins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Laura R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Allen Jenkins",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kenneth D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Victoria Black",
        "service_type": "Garage Door Installation",
        "tech_name": "Philip S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Robert Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "James Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Scott S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Douglas Kemp",
        "service_type": "Garage Door Insulation",
        "tech_name": "James J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Cathy Scott",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gerald A.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Karen Blackburn",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kaylee J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Lisa Pennington",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Catherine N.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Martinez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Beth D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Rhonda Odonnell",
        "service_type": "Garage Door Services",
        "tech_name": "Frank B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Christina Lowe",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Michael Watson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jordan B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "John Santiago",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tamara C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Stephen Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jodi B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Lauren Rivas",
        "service_type": "Garage Door Opener",
        "tech_name": "Denise L.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Brandi Boyd",
        "service_type": "Garage Door Opener",
        "tech_name": "Deborah P.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Tracy Valenzuela",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephanie S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Alyssa Vaughan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffrey L.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Johnathan Trujillo",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Tyler Schneider MD",
        "service_type": "Garage Door Insulation",
        "tech_name": "Megan C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Brandon Hamilton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Samuel D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Jessica Cook",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Donald B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Lisa Griffin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Deborah H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Carl Villarreal",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joanna M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Christine Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sean S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Carrie Sutton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tara H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Martin Lee",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jose J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Brittany Bryant",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Caleb S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Todd Robinson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Paul M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Tiffany Serrano",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Raymond Mcmillan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carol O.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Steve Garza",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sherri W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Mercedes Howard",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joshua S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Samantha Mcguire",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christine L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Jessica White",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Pamela M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Gregory Gilbert",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mary W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Barbara Fleming",
        "service_type": "Garage Door Installation",
        "tech_name": "Thomas C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Briana Watts",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Peter B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Benjamin S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Rachel Burch",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Eric W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Sheryl Allen",
        "service_type": "Garage Door Services",
        "tech_name": "Kristin R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Sarah Garcia",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Matthew Hart",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Laurie T.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Ruth Dudley",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jason D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Robert Baldwin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joshua J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Kayla Small",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Norman H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Alejandro Bennett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rebecca W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Kara Soto",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jessica Y.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Jason Moore",
        "service_type": "Garage Door Installation",
        "tech_name": "James W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Sarah Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kathy E.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Justin Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Kenneth Mitchell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kristine W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Robert Weaver",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tammy J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Garza",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joel S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Marcus Roberts",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cynthia B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Ms. Patricia Perry DDS",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jordan B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Daniel Ramirez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Larry S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Mark Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dawn C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Angela Suarez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Edward D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Nicole Jordan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kristin W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Clinton Anderson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Linda S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Andrew Mendoza",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ashley M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Joy Snyder",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Mrs. Alexandria Graham DDS",
        "service_type": "Garage Door Services",
        "tech_name": "Michelle W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Michael Mack",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Natalie Porter",
        "service_type": "Garage Door Repair",
        "tech_name": "Eric L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Brian Mccall",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Larry W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Sean Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christy G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Taylor Hill",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melissa B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Nicole Cruz DVM",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kathryn C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Cassandra Wilkins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alicia M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Brent Woods",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Wanda W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Shane Forbes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Zachary J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Timothy Daniels",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Diana W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Julia Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Justin F.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Christopher Mcgee",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brandon M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Nancy Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nicole F.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Karen Holt",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Rachel Mccarthy",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mitchell N.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Robert Vasquez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brian D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Edward Ross",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joseph F.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Melissa Bernard",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kim B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Melanie Thompson",
        "service_type": "Garage Door Services",
        "tech_name": "Stephanie J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Daniel Andrade DDS",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Victor M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Vanessa Hernandez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Edward C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Richard Ramos",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cynthia K.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Angel Velasquez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David Z.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Heather Lopez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jason C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Brittany Fisher",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lori F.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Sharon Robbins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Richard H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "James Bush",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Christie Russo",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Cindy Garcia",
        "service_type": "Garage Door Installation",
        "tech_name": "Stephanie C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Juan Day",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dustin B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Alicia Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sheri H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Karl Hopkins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "David Phillips",
        "service_type": "Garage Door Opener",
        "tech_name": "Deborah C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Lindsay King",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brianna L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Kathleen Bennett",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Felicia H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Timothy Miranda",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carly A.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Karen Hampton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jamie H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Nicole Anderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Deborah A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Stephen Key",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kyle H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Robert Macdonald",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Clinton M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Matthew Parker DDS",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ashley J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "John Wilson",
        "service_type": "Garage Door Opener",
        "tech_name": "Christina D.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Dylan Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Patricia Vasquez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Francisco Thomas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Elijah P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Allison Ramirez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katie R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Briana Martinez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Adam S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Gregory Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "John Stone",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stacey W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Joseph York",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brent M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Rebecca Nelson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Craig M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Daniel Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Deborah M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Marie Huber",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lorraine D.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Sandra Gray",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mary D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Julia Velasquez",
        "service_type": "Garage Door Opener",
        "tech_name": "Tyler P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Carlos Singleton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrea J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Julian Morton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jodi M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Blankenship",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joseph S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Mr. Zachary Owen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Catherine R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Ann Watson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Adam N.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Dylan Reyes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Margaret D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Chad Phillips",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Peter P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Chase White",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shannon A.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Victor Stewart",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Pam W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Tammy Harris",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ryan M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Anthony Spencer",
        "service_type": "Garage Door Repair",
        "tech_name": "Amber S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Patricia Kennedy",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lauren F.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Sara Wilson",
        "service_type": "Garage Door Opener",
        "tech_name": "Rodney R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Sheila Ward",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Devin B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Troy Arellano",
        "service_type": "Garage Door Repair",
        "tech_name": "Daniel W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Randy Taylor",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Peter W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Diana Reid",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tanya C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
